<template>
  <!--Button and dialog to show the table-->
  <v-container
    :style="$vuetify.breakpoint.mdOnly ? 'max-width:1000px' : ''"
    class="pb-0 px-0"
  >
    <!--TOTAL SALES-->
    <v-row>
      <v-col cols="12" sm="6" md="2">
        <v-card raised elevation="6" class="mt-1">
          <v-card-title>
            <h4
              style="
                font-size: 15px;
                text-transform: uppercase;
                font-weight: 100;
                text-decoration: underline;
              "
            >
              {{ $t("total ventas") }}
            </h4>
          </v-card-title>
          <v-simple-table style="background-color: #1e1e1e">
            <h1 v-if="totalSales == 0">-</h1>
            <h1 v-else class="pt-md-6">{{ totalSales }}</h1>
          </v-simple-table>
        </v-card>
        <!--SALES BY SERVICES-->
      </v-col>

      <!--SALES BY EMPLOYEE-->
      <v-col cols="5">
        <SalesByEmployee />
      </v-col>

      <!--SALES BY PRODUCTS-->
      <v-col cols="5">
        <SalesByProduct />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2"> </v-col>

      <!--SALES BY SERVICE-->
      <v-col cols="5">
        <SalesByService />
      </v-col>

      <!--SALES BY SUBSERVICE-->
      <v-col cols="5">
        <SalesBySubService />
      </v-col>
      <!--SALES BY PERIODO-->
      <v-col cols="2"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <SalesByPeriod />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SalesTable",
  components: {
    SalesByService: () => import("@/components/stats/sales/SalesByService"),
    SalesByEmployee: () => import("@/components/stats/sales/SalesByEmployee"),
    SalesByProduct: () => import("@/components/stats/sales/SalesByProduct"),
    SalesByPeriod: () => import("@/components/stats/sales/SalesByPeriod"),
    SalesBySubService: () =>
      import("@/components/stats/sales/SalesBySubService"),
  },
  data() {
    return {
      dialog: true,
      totalSales: 0,
    };
  },
  mounted() {
    this.reload();
  },
  watch: {
    filters: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("stats", ["filters"]),
  },
  methods: {
    ...mapActions("stats", ["getSales", "getProducts"]),

    reload() {
      this.fetchSales();
    },
    ...mapMutations("stats", ["SET_LIST"]),

    fetchSales() {
      this.getSales({
        paginate: {},
        filters: {},
      }).then((sales) => {
        this.totalSales = sales.totalSales;
        this.SET_LIST({
          value: sales.salesByEmployee,
          list: "salesByEmployee",
        });

        this.SET_LIST({ value: sales.salesByPeriod, list: "salesByPeriod" });
        this.SET_LIST({ value: sales.salesByProduct, list: "salesByProduct" });
        this.SET_LIST({ value: sales.salesByService, list: "salesByService" });
        this.SET_LIST({
          value: sales.salesBySubService,
          list: "salesBySubService",
        });
      });
    },
  },
};
</script>
<style></style>
